import classes from "./Sections.module.css";
import blogs from "../assets/blogs.json";
import blog1 from "../assets/blog1.png"; 
import blog2 from "../assets/blog2.png"; 
import blog3 from "../assets/blog3.png"; 

const BLOG_IMAGES = [blog1, blog2, blog3];

const BlogCard = ({ link, title, idx }) => {
  return (
    <a href={link} target="target_blank" className={classes.card}>
      <img className={classes.blogImage} src={BLOG_IMAGES[idx]} alt="" />
      <div className={classes.bottomCard}>
        <p className={classes.blogTitle}>{title}</p>
      </div>
    </a>
  );
};
const FeaturedBlogs = () => {
  return (
    <div className={classes.featuredBlogs}>
      <h2 className={classes.sectionTitle}>Featured Blogs</h2>

      <div className={classes.cardContainer}>
        {blogs.map((blog, idx) => (
          <BlogCard title={blog.title} link={blog.link} idx={idx} />
        ))}
        <a href="https://ashavive.hashnode.dev/" target="target_blank" className={classes.featureCard}> See more on Hashnode!</a>
      </div>
    </div>
  );
};

export default FeaturedBlogs;

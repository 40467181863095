import classes from "./Sections.module.css";
import Github from "../assets/github.png";
import Web from "../assets/web.png";
import projects from "../assets/projects.json";
import project1 from "../assets/project1.png";
import project2 from "../assets/project2.png";
import project3 from "../assets/project3.png";
import project4 from "../assets/project4.png";
import project5 from "../assets/project5.png";

const PROJECT_IMAGES = [project1, project2, project3, project4, project5];

const FeatureCard = ({ title, tags, githubLink, liveLink, idx }) => {
  return (
    <div className={classes.card}>
      <img className={classes.cardImage} src={PROJECT_IMAGES[idx]} alt="" />
      <div className={classes.bottomCard}>
        <p className={classes.title}>{title}</p>
        <ul className={classes.tags}>
          {tags.map((tag) => (
            <li key={tag} className={classes.tag}>
              {tag}
            </li>
          ))}
        </ul>
        <div className={classes.links}>
          <a href={liveLink} target="target_blank">
            <img src={Web} alt="" />
            View live project
          </a>
          <a href={githubLink} target="target_blank">
            <img src={Github} alt="" />
            View source code
          </a>
        </div>
      </div>
    </div>
  );
};
const FeaturedProjects = () => {
  return (
    <div className={classes.featuredProjects}>
      <h2 className={classes.sectionTitle}>Featured Projects</h2>

      <div className={classes.cardContainer}>
        {projects.map((project, idx) => (
          <FeatureCard
            key={idx}
            title={project.title}
            idx={idx}
            tags={project.tags}
            githubLink={project.githubLink}
            liveLink={project.liveLink}
          />
        ))}

        <a
          className={classes.featureCard}
          href="https://github.com/livinglifemeaning"
          target="target_blank"
        >
          <div>See more on Github!</div>
        </a>
      </div>
    </div>
  );
};

export default FeaturedProjects;

import classes from "./App.module.css";
import NameSVG from "./NameSVG";
import ProfilePhoto from "../assets/profilePhoto.png";
import FeaturedProjects from "./FeaturedProjects";
import FeaturedBlogs from "./FeaturedBlogs";
import SocialLinks from "./SocialLinks";
function App() {
  return (
    <main className={classes.main}>
      <div className={classes.headerBox}>
        <div>
          <h1 className={classes.heading}>
            Hi, I'm <span>Asha</span>
          </h1>
          <NameSVG />
          <p className={classes.description}>
            I develop and design frontend experiences
          </p>
        </div>
        <img className={classes.mainImage} src={ProfilePhoto} alt="Asha Cole" />
      </div>

      <FeaturedProjects />
      <FeaturedBlogs/> 
      <SocialLinks/> 
    </main>
  );
}

export default App;

import classes from "./NameSVG.module.css";


const NameSVG = () => {
  return (
    <div className={classes.svgs}>
      {/* A */}
      <svg
        width="100"
        height="120"
        viewBox="0 0 100 105"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="pinkgradient" x1="26%" y1="0%" x2="74%" y2="100%">
            <stop
              offset="0%"
              style={{ stopColor: "rgb(240,106,146)", stopOpacity: 1.0 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "rgb(255,216,166)", stopOpacity: 1.0 }}
            />
          </linearGradient>
        </defs>

        <g
          id="svgGroup"
          strokeLinecap="round"
          fillRule="evenodd"
          fontSize="9pt"
          stroke="none"
          strokeWidth=".5mm"
          fill="url('#pinkgradient')"
        >
          <path
            d="M 0 105 L 38.1 0 L 59.175 0 L 97.35 105 L 77.925 105 L 71.175 86.4 L 26.175 86.4 L 19.425 105 L 0 105 Z M 48.6 24.825 L 32.85 68.1 L 64.425 68.1 L 48.6 24.825 Z"
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </svg>

      {/* S */}

      <svg
        width="60"
        height="90"
        viewBox="0 0 60 79.006"
        xmlns="http://www.w3.org/2000/svg"
      >


        <g
          id="svgGroup"
          strokeLinecap="round"
          fillRule="evenodd"
          fontSize="9pt"
          stroke="none"
          strokeWidth=".5mm"
          fill="url('#pinkgradient')"
        >
          <path
            d="M 0 62.851 L 15.45 56.251 A 8.96 8.96 0 0 0 16.283 57.402 Q 17.109 58.384 18.375 59.438 A 16.592 16.592 0 0 0 21.367 61.408 A 20.654 20.654 0 0 0 23.213 62.251 A 15.836 15.836 0 0 0 27.748 63.306 A 19.069 19.069 0 0 0 29.4 63.376 Q 32.25 63.376 34.838 62.588 Q 37.425 61.801 39.038 60.151 A 5.584 5.584 0 0 0 40.583 56.902 A 7.67 7.67 0 0 0 40.65 55.876 Q 40.65 53.101 38.738 51.563 A 11.446 11.446 0 0 0 36.277 50.071 A 15.607 15.607 0 0 0 33.938 49.238 A 279.282 279.282 0 0 0 29.712 48.12 A 250.247 250.247 0 0 0 28.35 47.776 Q 21.225 46.351 15.113 43.351 A 30.155 30.155 0 0 1 9 39.422 A 25.231 25.231 0 0 1 5.287 35.588 A 17.496 17.496 0 0 1 1.706 26.537 A 22.677 22.677 0 0 1 1.575 24.076 A 23.463 23.463 0 0 1 2.559 17.157 A 19.836 19.836 0 0 1 5.513 11.251 Q 9.45 5.851 15.788 2.926 A 32.257 32.257 0 0 1 29.446 0.001 A 36.785 36.785 0 0 1 29.475 0.001 A 37.37 37.37 0 0 1 41.549 1.919 A 34.62 34.62 0 0 1 45.863 3.713 Q 53.325 7.426 57.675 14.176 L 43.2 22.726 A 11.469 11.469 0 0 0 41.565 20.578 A 14.57 14.57 0 0 0 40.275 19.388 A 17.05 17.05 0 0 0 36.592 17.1 A 19.345 19.345 0 0 0 36 16.838 Q 33.6 15.826 30.975 15.676 Q 27.6 15.526 24.825 16.276 A 10.701 10.701 0 0 0 22.462 17.21 A 8.37 8.37 0 0 0 20.363 18.826 Q 18.675 20.626 18.675 23.626 A 5.685 5.685 0 0 0 18.941 25.412 A 4.468 4.468 0 0 0 20.925 27.863 A 15.698 15.698 0 0 0 23.537 29.141 Q 24.823 29.636 26.315 29.99 A 25.685 25.685 0 0 0 26.363 30.001 A 95.197 95.197 0 0 1 31.74 31.428 A 86.011 86.011 0 0 1 32.475 31.651 Q 39.075 33.826 44.888 36.976 A 32.051 32.051 0 0 1 50.561 40.847 A 26.151 26.151 0 0 1 54.225 44.701 A 16.26 16.26 0 0 1 57.56 53.74 Q 57.606 54.425 57.606 55.133 A 21.996 21.996 0 0 1 57.6 55.651 Q 57.6 62.926 53.25 68.363 Q 48.9 73.801 42.038 76.651 A 30.919 30.919 0 0 1 30.06 79.005 A 34.996 34.996 0 0 1 27.375 78.901 Q 21.525 78.526 16.088 76.538 Q 10.65 74.551 6.45 71.101 Q 2.25 67.651 0 62.851 Z"
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </svg>

      {/* h */}
      <svg
        width="72"
        height="120"
        viewBox="0 0 72 112.5"
        xmlns="http://www.w3.org/2000/svg"
      >
        

        <g
          id="svgGroup"
          strokeLinecap="round"
          fillRule="evenodd"
          fontSize="9pt"
          stroke="none"
          strokeWidth=".5mm"
          fill="url('#pinkgradient')"
        >
          <path
            d="M 68.7 65.1 L 68.7 112.5 L 50.4 112.5 L 50.4 69.9 Q 50.4 65.475 48.225 61.838 Q 46.05 58.2 42.413 56.063 Q 38.775 53.925 34.35 53.925 Q 29.925 53.925 26.288 56.063 Q 22.65 58.2 20.513 61.838 Q 18.375 65.475 18.375 69.9 L 18.375 112.5 L 0.075 112.5 L 0 0 L 18.3 0 L 18.375 44.175 Q 22.2 40.125 27.525 37.763 A 27.928 27.928 0 0 1 38.808 35.401 A 31.918 31.918 0 0 1 39 35.4 Q 47.175 35.4 53.925 39.413 Q 60.675 43.425 64.688 50.138 A 28.551 28.551 0 0 1 68.699 64.843 A 33.779 33.779 0 0 1 68.7 65.1 Z"
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </svg>

      {/* a */}

      <svg
        width="80"
        height="85"
        viewBox="0 0 80 79.052"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="svgGroup"
          strokeLinecap="round"
          fillRule="evenodd"
          fontSize="9pt"
          stroke="none"
          strokeWidth=".5mm"
          fill="url('#pinkgradient')"
        >
          <path
            d="M 61.876 11.701 L 61.051 2.101 L 79.351 2.101 L 79.351 77.101 L 61.051 77.101 L 60.226 67.801 Q 57.151 72.826 52.089 75.938 A 20.819 20.819 0 0 1 44.259 78.747 A 27.179 27.179 0 0 1 40.126 79.051 Q 31.801 79.051 24.526 75.901 Q 17.251 72.751 11.739 67.201 Q 6.226 61.651 3.114 54.376 Q 0.001 47.101 0.001 38.776 Q 0.001 30.751 2.964 23.701 Q 5.926 16.651 11.251 11.326 Q 16.576 6.001 23.551 3.001 A 37.435 37.435 0 0 1 38.05 0.004 A 42.675 42.675 0 0 1 38.551 0.001 A 28.63 28.63 0 0 1 46.724 1.134 A 25.014 25.014 0 0 1 51.864 3.301 Q 57.751 6.601 61.876 11.701 Z M 39.676 61.426 A 19.864 19.864 0 0 0 45.91 60.474 A 17.595 17.595 0 0 0 50.026 58.501 Q 54.526 55.576 57.076 50.588 A 23.503 23.503 0 0 0 59.604 40.717 A 27.59 27.59 0 0 0 59.626 39.601 A 25.052 25.052 0 0 0 58.528 32.096 A 22.577 22.577 0 0 0 57.076 28.538 Q 54.526 23.551 49.989 20.626 A 18.447 18.447 0 0 0 40.438 17.714 A 22.331 22.331 0 0 0 39.676 17.701 Q 33.901 17.701 29.139 20.663 Q 24.376 23.626 21.564 28.576 A 21.748 21.748 0 0 0 18.762 38.846 A 25.8 25.8 0 0 0 18.751 39.601 A 22.313 22.313 0 0 0 20.191 47.67 A 21.024 21.024 0 0 0 21.601 50.626 Q 24.451 55.576 29.214 58.501 Q 33.976 61.426 39.676 61.426 Z"
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </svg>
    </div>
  );
};

export default NameSVG;

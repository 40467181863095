import classes from "./Sections.module.css";
import LinkedIn from "../assets/linkedin.png";
import Twitter from "../assets/twitter.webp";
import Github from "../assets/github.png";
import Hashnode from "../assets/hashnode.png";
const SocialLinks = () => {
  return (
    <div className={classes.socialSection}>
      <h2 className={classes.sectionTitle}>Let's keep in touch!</h2>
      <ul className={classes.socialLinks}>
        <li>
          <a
            className={classes.socialLink}
            href="https://www.linkedin.com/in/asha-cole-vive/
            "
            target="target_blank"
          >
            <span>
              <img src={LinkedIn} alt="" />
            </span>
            Connect with me on LinkedIn
          </a>
        </li>

        <li>
          <a
            className={classes.socialLink}
            href="https://twitter.com/AshaViviente"
            target="target_blank"
          >
            <span>
              <img src={Twitter} alt="" />
            </span>
            Follow me on Twitter
          </a>
        </li>

        <li>
          <a
            className={classes.socialLink}
            href="https://github.com/livinglifemeaning"
            target="target_blank"
          >
            <span>
              <img src={Github} alt="" />
            </span>
            See my projects on Github
          </a>
        </li>
        <li style={{ textDecoration: "none", color: "red" }}>
          <a
            className={classes.socialLink}
            href="https://ashavive.hashnode.dev/"
            target="target_blank"
          >
            <span>
              <img src={Hashnode} alt="" />
            </span>
            Read my blog on Hashnode
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialLinks;
